import { axiosInstance, unverifiedInstance } from '../config';
import { authStart, authSuccess, authError, updateStart, updateError, updateSuccess } from './authSlice';
import { addNotification } from './notificationSlice';
import { v4 } from 'uuid';

export const login = async (user, dispatch) => {
  dispatch(authStart());
  try {
    const res = await unverifiedInstance.post('/auth/login', user);
    console.log(res.data);
    dispatch(authSuccess(res.data));
    window.location.reload(false);
  } catch (err) {
    dispatch(authError());
    dispatch(
      addNotification({
        id: v4(),
        type: 'Error',
        message: 'Wrong credentials',
      })
    );
  }
};

export const register = async (user, dispatch) => {
  dispatch(authStart());
  const password = user.password;
  try {
    const res = await unverifiedInstance.post('/auth/register', user);
    dispatch(authSuccess(res.data));
    login({ ...res.data, password }, dispatch);
  } catch (err) {
    dispatch(authError());
    console.log(err.response.data);
    if (!err.response.data) {
      dispatch(
        addNotification({
          id: v4(),
          type: 'Error',
          message: 'Something went wrong',
        })
      );
    } else {
      dispatch(
        addNotification({
          id: v4(),
          type: 'Error',
          message: err.response.data,
        })
      );
    }
  }
};

export const update = async (payload, dispatch, id) => {
  dispatch(updateStart());
  try {
    const res = await axiosInstance.put(`/users/${id}`, payload);
    dispatch(
      addNotification({
        id: v4(),
        type: 'Success',
        message: 'Profile Updated successfully',
      })
    );
    dispatch(updateSuccess(res.data));
  } catch (err) {
    dispatch(updateError());
    dispatch(
      addNotification({
        id: v4(),
        type: 'Error',
        message: 'Something went wrong',
      })
    );
  }
};
