import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { mobile } from '../../responsive';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;
const AddComment = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: #7a77771a;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 10px;

  h1 {
    color: var(--color-secondary);
    font-size: 32px;
    font-family: var(--font-title);
    ${mobile({
      fontSize: '24px',
    })}
  }
  p {
    font-size: 14px;
    font-family: var(--font-primary);
    color: var(--color-secondary);
    ${mobile({
      fontSize: '12px',
    })}
    margin-top:15px;

    a {
      color: var(--color-primary);
      font-size: 16px;
      margin: 0 2px;
      font-weight: 500;
    }
  }
`;

const LikeMsg = () => {
  return (
    <Container>
      <AddComment>
        <h1>You are not Signed in</h1>
        <p>
          You need to{' '}
          <Link to='/register' className='link'>
            sign up
          </Link>{' '}
          or{' '}
          <Link to='/login' className='link'>
            login
          </Link>{' '}
          in order to leave a like.{' '}
        </p>
      </AddComment>
    </Container>
  );
};
export default LikeMsg;
