import styled from 'styled-components';
import BottomDetail from '../BottomDetail/BottomDetail';
import TopDetail from '../TopDetail/TopDetail';
import { BsBookmark, BsFillBookmarkFill, BsThreeDotsVertical } from 'react-icons/bs';
import { audio } from '../../assets';
import { useState } from 'react';
import { axiosInstance } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { mobile } from '../../responsive';
import { DeleleContainer } from '..';
import { addNotification } from '../../store/notificationSlice';
import { v4 } from 'uuid';

const Container = styled.div`
  width: 90%;
  background-color: #fff;
  min-height: 30vh;
  box-shadow: var(--box-shadow);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 50px;
  margin-top: 20px;
  position: relative;
`;

const Center = styled.div`
  flex: 12;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const AudioContainer = styled.div`
  width: 100%;
  audio {
    width: 100%;
  }
`;

const Bookmark = styled(BsBookmark)`
  color: #41d3bd;
  font-size: 28px;
  cursor: pointer;
  position: absolute;
  right: 40px;
  ${mobile({
    fontSize: '20px',
    right: '7px',
    top: '7px',
  })}
`;
const BookmarkFill = styled(BsFillBookmarkFill)`
  color: #41d3bd;
  font-size: 28px;
  cursor: pointer;
  position: absolute;
  right: 40px;
  ${mobile({
    fontSize: '20px',
    right: '5px',
    top: '7px',
  })}
`;

const Dots = styled(BsThreeDotsVertical)`
  color: var(--color-secondary);
  font-size: 20px;
  cursor: pointer;

  ${mobile({
    fontSize: '20px',
  })}
`;
const DotsContainer = styled.div`
  position: absolute;
  right: 5px;
  div {
    position: relative;
  }
  ul {
    position: absolute;
    list-style: none;
    padding: 5px 0;
    right: 0;
    top: 30px;
    background-color: #e2e2e2;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    li {
      color: #00171f;
      padding: 5px 10px;
      font-family: var(--font-secondary);
      font-size: 14px;
      cursor: default;
      &:hover {
        background-color: #c8c8c8;
      }
    }
  }
`;

const EditContainer = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

const EditBackground = styled.div`
  width: 100%;
  height: 100%;
`;

const SoundPost = post => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const [bookmarked, setBookmarked] = useState(post?.bookmarks?.includes(user?._id));
  const [active, setActive] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const handleBookmark = () => {
    !bookmarked ? axiosInstance.put(`/posts/bookmark/add/${post._id}`) : axiosInstance.put(`/posts/bookmark/remove/${post._id}`);
  };
  const handleDropdown = () => {
    if (dropdown) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const handleDelete = async () => {
    const res = await axiosInstance.delete(`/posts/${user._id}/${post._id}`);
    if (res.status === 200) {
      window.location.reload(false);
    } else {
      dispatch(
        addNotification({
          type: 'Error',
          message: 'Something went wrong',
          id: v4(),
        })
      );
    }
  };

  return (
    <Container>
      <TopDetail post={post} setActive={setActive} />
      <Center>
        <AudioContainer>
          <audio controls controlsList='nodownload'>
            <source src={post?.file} type='audio/mp3' />
          </audio>
        </AudioContainer>
      </Center>
      <BottomDetail post={post} />
      {!bookmarked ? (
        <Bookmark
          onClick={() => {
            setBookmarked(true);
            handleBookmark();
          }}
        />
      ) : (
        <BookmarkFill
          onClick={() => {
            setBookmarked(false);
            handleBookmark();
          }}
        />
      )}
      <DotsContainer>
        <div>
          <Dots onClick={handleDropdown} />
          {dropdown && (
            <ul>
              {post?.userId === user?._id || user?.isAdmin ? (
                <>
                  <li>Edit</li>
                  <li onClick={handleDelete}>Delete</li>
                </>
              ) : (
                <>
                  <li>Report</li>
                </>
              )}
            </ul>
          )}
        </div>
      </DotsContainer>
    </Container>
  );
};
export default SoundPost;
