import { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { axiosInstance } from '../../config';
import Avatar from '../Avatar/Avatar';

const Container = styled.div`
  width: 20%;
  height: 45%;
  position: absolute;
  z-index: 100;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: var(--box-shadow);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  position: relative;
  overflow-y: auto;
  z-index: 300;
  ul {
    list-style: none;
    li {
      padding: 10px 30px;
      font-size: 18px;
      font-family: var(--font-primary);
      display: flex;
      align-items: center;
      text-transform: capitalize;
      color: var(--color-secondary);
      cursor: pointer;
      div {
        margin-right: 10px;
      }
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-family: var(--font-title);
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`;

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 32px;
  color: #7a7777;
`;

const Following = ({ type, id, closePopup }) => {
  const navigate = useNavigate();
  const [followers, setFollowers] = useState([]);
  const getFollowers = async () => {
    const { data } = await axiosInstance.get(`/users/${id}/${type}`);
    setFollowers(data);
  };

  useEffect(() => {
    getFollowers();
  }, []);
  return (
    <Container>
      <Wrapper>
        <Title>{type}</Title>
        <ul>
          {followers?.map(item => (
            <Link to={`/profile/${item._id}`} key={item._id} className={'link'}>
              <li onClick={closePopup}>
                <Avatar img={item?.avatar} isDynamic={true} size={'32px'} />
                {item?.username}
              </li>
            </Link>
          ))}
        </ul>
        <Close onClick={closePopup}>
          <IoMdClose />
        </Close>
      </Wrapper>
    </Container>
  );
};
export default Following;
