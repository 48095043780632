import styled from 'styled-components';
import { mobile } from '../../responsive';

const Container = styled.div`
  padding: 60px 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  ${mobile({
    padding: '60px 20px',
  })}

  h1 {
    font-family: var(--font-title);
    font-weight: 600;
    margin-bottom: 40px;
    ${mobile({
      fontSize: '24px',
      marginBottom: '20px',
    })}
  }
  p {
    font-family: var(--font-title);
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    ${mobile({
      fontSize: '18px',
      lineHeight: '20px',
    })}
  }
`;

const NotMatch = () => {
  return (
    <Container>
      <h1>Don't wander around too much.</h1>
      <p> There is no such link .</p>
    </Container>
  );
};
export default NotMatch;
