import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { register } from '../../store/apiCalls';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { mobile, tablet } from '../../responsive';
import { Otp } from '../../Components';
import { addNotification } from '../../store/notificationSlice';
import { v4 } from 'uuid';
import { TiTick } from 'react-icons/ti';
import { CgClose } from 'react-icons/cg';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Wrapper = styled.div`
  width: 30%;
  min-height: 85%;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  ${mobile({
    width: '80%',
  })}
  ${tablet({
    width: '60%',
    height: '60%',
  })}
`;

const Logo = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  font-family: var(--font-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;
const Form = styled.form`
  flex: 4;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  h1 {
    font-family: var(--font-title);
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
  }
`;

const Inputs = styled.div`
  flex: 4;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 150px;
    padding: 10px 20px;
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: white;
    background-color: #41d3bd;
    outline: none;
    border: none;
    border-radius: 7px;
    cursor: pointer;
  }
`;
const Links = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    font-family: var(--font-primary);
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    span {
      color: #41d3bd;
      cursor: pointer;
    }
  }
`;
const EditContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

const EditBackground = styled.div`
  width: 100%;
  height: 100%;
`;

const Validation = styled.div`
  display: none;
  position: absolute;
  left: 20px;
  bottom: 70px;

  div {
    position: relative;
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    box-shadow: var(--box-shadow);
    z-index: 300;
    ul {
      list-style: none;
    }
  }
`;

const List = styled.li`
  display: flex;
  align-items: center;

  font-family: var(--font-primary);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${props => (props.error ? 'red' : 'var(--color-primary)')};
  svg {
    margin-right: 5px;
    border: 1px solid ${props => (props.error ? 'red' : 'var(--color-primary)')};
    border-radius: 50%;
  }
`;

const Input = styled.div`
  position: relative;

  width: 100%;
  input {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    font-family: var(--font-title);
    background-color: rgba(122, 119, 119, 0.1);
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #7a7777;
    border: none;
    outline: none;
    border-radius: 7px;
    margin-bottom: 5px;
    ${mobile({
      fontSize: '16px',
    })}
    &:focus {
      & + ${Validation} {
        display: block;
      }
    }
  }
`;

const Triangle = styled.span`
  position: absolute;
  bottom: -40px;
  left: 30px;
  div {
    background-color: transparent;
    box-shadow: none;
    width: 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
    /* background-color: black; */

    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background: #fff;
      transform: rotate(45deg); /* Prefixes... */
      bottom: 30px;
      left: 10px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    }
  }
`;

const Register = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    username: '',
    contact: '',
    email: '',
    confirmPassword: '',
  });
  const [otpActive, setOtpActive] = useState(false);
  const [valid, setValid] = useState({
    username: false,
    email: false,
    password: false,
    contact: false,
    confirmPassword: false,
  });

  const [pswValid, setPswValid] = useState({
    uppercase: false,
    specialChar: false,
    number: false,
    length: false,
  });

  const handleUserChange = e => {
    if (e.target.validity.valid) {
      setValid(prev => ({
        ...prev,
        [e.target.name]: true,
      }));
    } else {
      setValid(prev => ({
        ...prev,
        [e.target.name]: false,
      }));
    }
    setUser(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handlePhoneChange = e => {
    if (e.target.validity.valid) {
      setValid(prev => ({
        ...prev,
        [e.target.name]: true,
      }));
    } else {
      setValid(prev => ({
        ...prev,
        [e.target.name]: false,
      }));
    }
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setUser(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const handlePasswordChange = e => {
    setUser(prev => ({ ...prev, [e.target.name]: e.target.value }));
    if (e.target.value.length > 5 && e.target.value.length < 17) {
      setPswValid(prev => ({
        ...prev,
        length: true,
      }));
    } else {
      setPswValid(prev => ({
        ...prev,
        length: false,
      }));
    }

    if (e.target.value.match(/[A-Z]/)) {
      setPswValid(prev => ({
        ...prev,
        uppercase: true,
      }));
    } else {
      setPswValid(prev => ({
        ...prev,
        uppercase: false,
      }));
    }
    if (e.target.value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
      setPswValid(prev => ({
        ...prev,
        specialChar: true,
      }));
    } else {
      setPswValid(prev => ({
        ...prev,
        specialChar: false,
      }));
    }
    if (e.target.value.match(/[0-9]/)) {
      setPswValid(prev => ({
        ...prev,
        number: true,
      }));
    } else {
      setPswValid(prev => ({
        ...prev,
        number: false,
      }));
    }
  };

  const handlePhoneNumberVerify = e => {
    if (user.username === '' || user.contact === '' || user.password === '' || user.confirmPassword === '' || user.email === '') {
      e.preventDefault();
      dispatch(
        addNotification({
          type: 'Error',
          id: v4(),
          message: 'Please fill all the fields',
        })
      );
    } else if (!valid.username) {
      e.preventDefault();
      dispatch(
        addNotification({
          type: 'Error',
          id: v4(),
          message: 'Please enter a valid username',
        })
      );
    } else if (!valid.email) {
      e.preventDefault();
      dispatch(
        addNotification({
          type: 'Error',
          id: v4(),
          message: 'Please enter a valid email',
        })
      );
    } else if (!valid.contact) {
      e.preventDefault();
      dispatch(
        addNotification({
          type: 'Error',
          id: v4(),
          message: 'Please enter a valid contact number',
        })
      );
    } else if (!pswValid.length || !pswValid.number || !pswValid.specialChar || !pswValid.uppercase) {
      e.preventDefault();
      dispatch(
        addNotification({
          type: 'Error',
          id: v4(),
          message: 'Please enter a valid password',
        })
      );
    } else if (!valid.confirmPassword) {
      e.preventDefault();
      dispatch(
        addNotification({
          type: 'Error',
          id: v4(),
          message: 'Passwords do not match',
        })
      );
    } else {
      e.preventDefault();
      const { confirmPassword, ...info } = user;
      register(info, dispatch);
    }
  };

  return (
    <Container>
      <Wrapper>
        <Logo>Logo</Logo>
        <Form onSubmit={handlePhoneNumberVerify}>
          <h1>Register</h1>
          <Inputs>
            <Input>
              <input
                placeholder='Enter your username'
                type='text'
                value={user.username}
                onChange={handleUserChange}
                name='username'
                // !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?
                pattern={`^[A-Za-z0-9!@#$%^&*()_+-={};':"\\|,.<>\/?]{3,15}$`}
                autoComplete='off'
                required={true}
              />
              <Validation>
                <div>
                  <Triangle>
                    <div></div>
                  </Triangle>
                  <ul>
                    <List error={!valid.username}>{valid.username ? <TiTick /> : <CgClose />} Username should be 3-15 characters</List>
                  </ul>
                </div>
              </Validation>
            </Input>
            <Input>
              <input
                placeholder='Enter your contact'
                type='text'
                value={user.contact}
                onChange={handlePhoneChange}
                name='contact'
                pattern='^(98)[0-9]{8}$'
                autoComplete='off'
                required={true}
                maxLength={10}
              />
              <Validation>
                <div>
                  <Triangle>
                    <div></div>
                  </Triangle>
                  <ul>
                    <List error={!valid.contact}>{valid.contact ? <TiTick /> : <CgClose />} Enter a valid phone number</List>
                  </ul>
                </div>
              </Validation>
            </Input>
            <Input>
              <input
                placeholder='Enter your Email'
                type='email'
                value={user.email}
                onChange={handleUserChange}
                name='email'
                pattern='[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'
                autoComplete='off'
                required={true}
              />
            </Input>
            <Input>
              <input
                placeholder='Enter your password'
                type='password'
                value={user.password}
                onChange={handlePasswordChange}
                name='password'
                pattern='^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$'
                autoComplete='off'
              />
              <Validation>
                <div>
                  <Triangle>
                    <div></div>
                  </Triangle>
                  <ul>
                    <List error={!pswValid.uppercase}>{pswValid.uppercase ? <TiTick /> : <CgClose />} Password should contain an uppercase letter</List>
                    <List error={!pswValid.specialChar}>{pswValid.specialChar ? <TiTick /> : <CgClose />} Password should contain a special character</List>
                    <List error={!pswValid.number}>{pswValid.number ? <TiTick /> : <CgClose />} Password should contain a number</List>
                    <List error={!pswValid.length}>{pswValid.length ? <TiTick /> : <CgClose />} Password should be 6-16 characters</List>
                  </ul>
                </div>
              </Validation>
            </Input>
            <Input>
              <input
                placeholder='Confirm your password'
                type='password'
                value={user.confirmPassword}
                onChange={handleUserChange}
                name='confirmPassword'
                pattern={user.password}
                autoComplete='off'
                required={true}
              />
              <Validation>
                <div>
                  <Triangle>
                    <div></div>
                  </Triangle>
                  <ul>
                    <List error={!valid.confirmPassword}>{valid.confirmPassword ? <TiTick /> : <CgClose />} Passwords should match</List>
                  </ul>
                </div>
              </Validation>
            </Input>
            <Links>
              <div>
                Already have an account?{' '}
                <span>
                  <Link className='link' to='/login'>
                    Sign in
                  </Link>
                </span>
              </div>
            </Links>
          </Inputs>
          <ButtonContainer>
            <button onClick={handlePhoneNumberVerify}>Register</button>
          </ButtonContainer>
        </Form>
      </Wrapper>
      {otpActive && (
        <EditContainer>
          <EditBackground onClick={() => setOtpActive(false)}></EditBackground>
          <Otp setOtpActive={setOtpActive} />
        </EditContainer>
      )}
    </Container>
  );
};
export default Register;
