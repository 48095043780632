import axios from 'axios';

export const axiosInstance = axios.create({
  // baseURL: 'https://memify34.herokuapp.com/api/',
  baseURL: 'https://server.jhirjhire.com/api/',
  // baseURL: 'http://localhost:8800/api/',
  headers: {
    token: `Bearer ${JSON.parse(localStorage.getItem('memify-cache-user'))?.accessToken}`,
  },
});

export const unverifiedInstance = axios.create({
  baseURL: 'https://server.jhirjhire.com/api/',
  // baseURL: 'https://memify34.herokuapp.com/api/',
  // baseURL: 'http://localhost:8800/api/',
});
