import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { unverifiedInstance } from '../../config';
import { mobile, tablet } from '../../responsive';
import { addNotification } from '../../store/notificationSlice';
import { keyframes } from 'styled-components';
import { TiTick } from 'react-icons/ti';
import { CgClose } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';

const Rotate = keyframes`
from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
`;

const Circle = styled.div`
  width: 20px;
  height: 20px;
  margin: 1px auto 0;
  border: solid 1px white;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: ${Rotate};
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  transition: all 0.5s ease-in;
  animation-name: ${Rotate};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Wrapper = styled.div`
  width: 35%;
  height: 45%;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  ${mobile({
    width: '80%',
  })}
  ${tablet({
    width: '60%',
  })}
`;

const Logo = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  font-family: var(--font-primary);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Form = styled.form`
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  h1 {
    font-family: var(--font-title);
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
  }
`;

const Inputs = styled.div`
  flex: 3;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  input {
    height: 50px;
    padding: 0 20px;
    font-family: var(--font-title);
    background-color: rgba(122, 119, 119, 0.1);
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #7a7777;
    border: none;
    outline: none;
    border-radius: 7px;
    margin-bottom: 10px;
    ${mobile({
      fontSize: '16px',
    })}
  }
`;
const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 180px;
    padding: 10px 20px;
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: white;
    background-color: #41d3bd;
    outline: none;
    border: none;
    border-radius: 7px;
    cursor: pointer;
  }
`;

const Text = styled.div`
  display: flex;
  flex: 2;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  h1 {
    font-family: var(--font-title);
    font-size: 24px;
    font-weight: 500;
    color: var(--color-primary);
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-secondary);
    font-family: var(--font-secondary);
  }
`;

const Validation = styled.div`
  display: none;
  position: absolute;
  left: 20px;
  bottom: 70px;

  div {
    position: relative;
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    box-shadow: var(--box-shadow);
    z-index: 300;
    ul {
      list-style: none;
    }
  }
`;

const List = styled.li`
  display: flex;
  align-items: center;

  font-family: var(--font-primary);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${props => (props.error ? 'red' : 'var(--color-primary)')};
  svg {
    margin-right: 5px;
    border: 1px solid ${props => (props.error ? 'red' : 'var(--color-primary)')};
    border-radius: 50%;
  }
`;

const Input = styled.div`
  position: relative;

  width: 100%;
  input {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    font-family: var(--font-title);
    background-color: rgba(122, 119, 119, 0.1);
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #7a7777;
    border: none;
    outline: none;
    border-radius: 7px;
    margin-bottom: 5px;
    ${mobile({
      fontSize: '16px',
    })}
    &:focus {
      & + ${Validation} {
        display: block;
      }
    }
  }
`;

const Triangle = styled.span`
  position: absolute;
  bottom: -40px;
  left: 30px;
  div {
    background-color: transparent;
    box-shadow: none;
    width: 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
    /* background-color: black; */

    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background: #fff;
      transform: rotate(45deg); /* Prefixes... */
      bottom: 30px;
      left: 10px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    }
  }
`;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [id, setId] = useState('');
  const [input, setInput] = useState({ email: '' });
  const [fetching, setFetching] = useState(false);
  const [sentOTP, setSentOTP] = useState(false);
  const [otp, setOtp] = useState('');
  const [verified, setVerified] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [pswValid, setPswValid] = useState({
    uppercase: false,
    specialChar: false,
    number: false,
    length: false,
  });
  const [valid, setValid] = useState({
    confirmPassword: false,
  });

  const handleChange = e => {
    if (e.target.validity.valid) {
      setValid(prev => ({
        ...prev,
        [e.target.name]: true,
      }));
    } else {
      setValid(prev => ({
        ...prev,
        [e.target.name]: false,
      }));
    }
    setConfirmPassword(e.target.value);
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
    if (e.target.value.length > 5 && e.target.value.length < 17) {
      setPswValid(prev => ({
        ...prev,
        length: true,
      }));
    } else {
      setPswValid(prev => ({
        ...prev,
        length: false,
      }));
    }

    if (e.target.value.match(/[A-Z]/)) {
      setPswValid(prev => ({
        ...prev,
        uppercase: true,
      }));
    } else {
      setPswValid(prev => ({
        ...prev,
        uppercase: false,
      }));
    }
    if (e.target.value.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
      setPswValid(prev => ({
        ...prev,
        specialChar: true,
      }));
    } else {
      setPswValid(prev => ({
        ...prev,
        specialChar: false,
      }));
    }
    if (e.target.value.match(/[0-9]/)) {
      setPswValid(prev => ({
        ...prev,
        number: true,
      }));
    } else {
      setPswValid(prev => ({
        ...prev,
        number: false,
      }));
    }
  };

  const handleSendOTP = async e => {
    setFetching(true);
    console.log(input);
    e.preventDefault();
    if (input.email.length === 0) {
      dispatch(
        addNotification({
          id: v4(),
          type: 'Error',
          message: 'Please enter your email address',
        })
      );
      setFetching(false);
      return;
    } else {
      try {
        const res = await unverifiedInstance.get(`/users/emailfind/${input.email}`);
        setId(res.data);
        try {
          await unverifiedInstance.post('/auth/otp', input);
          dispatch(
            addNotification({
              id: v4(),
              type: 'Success',
              message: 'OTP sent to your email',
            })
          );
          setSentOTP(true);
          setFetching(false);
        } catch (error) {
          dispatch(
            addNotification({
              id: v4(),
              type: 'Error',
              message: 'Something went wrong',
            })
          );
          setFetching(false);
        }
      } catch (error) {
        dispatch(
          addNotification({
            id: v4(),
            type: 'Error',
            message: 'Email not found',
          })
        );
        setFetching(false);
      }
    }
  };

  const verifyOTP = async e => {
    e.preventDefault();
    if (otp.length === 0) {
      dispatch(
        addNotification({
          id: v4(),
          type: 'Error',
          message: 'Please enter OTP',
        })
      );
      return;
    } else {
      try {
        await unverifiedInstance.post('/auth/verify', { otp: otp });
        dispatch(
          addNotification({
            id: v4(),
            type: 'Success',
            message: 'OTP verified',
          })
        );
        setVerified(true);
      } catch (err) {
        dispatch(
          addNotification({
            id: v4(),
            type: 'Error',
            message: 'Wrong OTP has been entered',
          })
        );
      }
    }
  };

  const handleChangePassword = async e => {
    e.preventDefault();
    if (password.length === 0 || confirmPassword.length === 0) {
      dispatch(
        addNotification({
          id: v4(),
          type: 'Error',
          message: 'Please fill out all the fields',
        })
      );
      return;
    }
    if (password !== confirmPassword) {
      dispatch(
        addNotification({
          id: v4(),
          type: 'Error',
          message: 'Passwords do not match',
        })
      );
      return;
    } else if (!pswValid.length || !pswValid.number || !pswValid.specialChar || !pswValid.uppercase) {
      e.preventDefault();
      dispatch(
        addNotification({
          type: 'Error',
          id: v4(),
          message: 'Please enter a valid password',
        })
      );
    } else {
      try {
        await unverifiedInstance.put(`/users/updatepassword/${id}`, { password: password });
        navigate('/login', { state: { message: 'Password changed successfully' } });
      } catch (err) {
        dispatch(
          addNotification({
            id: v4(),
            type: 'Error',
            message: 'Something went wrong',
          })
        );
      }
    }
  };

  return (
    <Container>
      <Wrapper>
        <Logo>
          <h1>Logo</h1>{' '}
        </Logo>
        {!sentOTP ? (
          <Form>
            <Text>
              <h1>Forgot Password</h1>
              <p>Enter your email to get an OTP to change your password</p>
            </Text>
            <Inputs>
              <input
                type='email'
                placeholder='Enter your email address'
                value={input.email}
                onChange={e => {
                  setInput({ ...input, email: e.target.value });
                }}
              />
            </Inputs>
            <ButtonContainer>
              <button onClick={handleSendOTP}>{fetching ? <Circle /> : 'Send OTP'}</button>
            </ButtonContainer>
          </Form>
        ) : !verified ? (
          <Form>
            <Text>
              <h1>Enter OTP</h1>
              <p>Enter the OTP to change your password</p>
            </Text>
            <Inputs>
              <input type='text' placeholder='Enter OTP' value={otp} onChange={e => setOtp(e.target.value)} />
            </Inputs>
            <ButtonContainer>
              <button onClick={verifyOTP}>{fetching ? <Circle /> : 'Submit'}</button>
            </ButtonContainer>
          </Form>
        ) : (
          <Form>
            <Text>
              <h1>Change Password</h1>
            </Text>
            <Inputs>
              <Input>
                <input
                  placeholder='Enter your password'
                  type='password'
                  value={password}
                  onChange={handlePasswordChange}
                  name='password'
                  pattern='^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$'
                  autoComplete='off'
                  required={true}
                />
                <Validation>
                  <div>
                    <Triangle>
                      <div></div>
                    </Triangle>
                    <ul>
                      <List error={!pswValid.uppercase}>{pswValid.uppercase ? <TiTick /> : <CgClose />} Password should contain an uppercase letter</List>
                      <List error={!pswValid.specialChar}>{pswValid.specialChar ? <TiTick /> : <CgClose />} Password should contain a special character</List>
                      <List error={!pswValid.number}>{pswValid.number ? <TiTick /> : <CgClose />} Password should contain a number</List>
                      <List error={!pswValid.length}>{pswValid.length ? <TiTick /> : <CgClose />} Password should be 6-16 characters</List>
                    </ul>
                  </div>
                </Validation>
              </Input>
              <Input>
                <input placeholder='Confirm your password' type='password' value={confirmPassword} onChange={handleChange} name='confirmPassword' pattern={password} autoComplete='off' />
                <Validation>
                  <div>
                    <Triangle>
                      <div></div>
                    </Triangle>
                    <ul>
                      <List error={!valid.confirmPassword}>{valid.confirmPassword ? <TiTick /> : <CgClose />} Passwords should match</List>
                    </ul>
                  </div>
                </Validation>
              </Input>
            </Inputs>
            <ButtonContainer>
              <button onClick={handleChangePassword}>{fetching ? <Circle /> : 'Submit'}</button>
            </ButtonContainer>
          </Form>
        )}
      </Wrapper>
    </Container>
  );
};
export default ForgotPassword;
